<template>
  <v-row justify="center">
    <v-dialog v-model="shower" scrollable persistent max-width="700px">
      <v-card v-if="progressdatasettingstorage.length === 0 && mutiprogress === true && statusmulti === 'ALL'">
        <!-- <v-card-text class="pa-5">
          <v-layout class="pt-2 pb-1">
            <v-flex d-flex justify-center class="ml-8">
            </v-flex>
            <v-flex xs1 d-flex justify-end>
              <v-icon
                :color="$vuetify.theme.dark ? 'white' : color.theme"
                @click="close()"
                >mdi-close</v-icon
              >
            </v-flex>
          </v-layout>
        </v-card-text> -->
        <v-card-text style="height:350px;" class="text-center pt-8">
          <v-progress-circular indeterminate size="250" v-if="loadprogress === true">
            <span style="font-size:20px">{{ $t("quota_storage.updatingsetting") }}</span>
          </v-progress-circular>
          <div v-else>
            <v-icon color="success" size="260">
               mdi-check-circle-outline
            </v-icon>
            <br>
            <span style="font-size:20px">{{ $t("quota_storage.updatesettingsuccess") }}</span>
          </div>
        </v-card-text>
        <!-- <v-divider></v-divider> -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" outlined @click="close()">{{
            $t("admin.close")
          }}</v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else>
        <v-card-title v-if="statusmulti === 'SELECT'">
          {{ $t("quota_storage.settingselect") }}
          <v-spacer></v-spacer>
          <v-icon :color="color.theme" dense dark size="35">mdi-server</v-icon>
        </v-card-title>
        <v-card-title v-else-if="statusmulti === 'ALL'">
          {{ $t("quota_storage.settingall") }}
          <v-spacer></v-spacer>
          <v-icon :color="color.theme" dense dark size="35">mdi-server</v-icon>
        </v-card-title>
        <v-card-title v-else-if="datastorage.user_name_en && $t('default') === 'en'">
          {{ $t("quota_storage.setting") }} <br v-if="resolutionScreen < 400"> {{ datastorage.user_name_en}}
          <v-spacer></v-spacer>
          <v-icon :color="color.theme" dense dark size="35">mdi-server</v-icon>
        </v-card-title>
        <v-card-title v-else-if="datastorage.user_name_th && $t('default') === 'th'">
          {{ $t("quota_storage.setting") }} <br v-if="resolutionScreen < 400"> {{ datastorage.user_name_th}}
          <v-spacer></v-spacer>
          <v-icon :color="color.theme" dense dark size="35">mdi-server</v-icon>
        </v-card-title>
        <v-card-title v-else-if="datastorage.department_name">
          {{ $t("quota_storage.setting") }} <br v-if="resolutionScreen < 400"> {{ datastorage.department_name}}
          <v-spacer></v-spacer>
          <v-icon :color="color.theme" dense dark size="35">mdi-server</v-icon>
        </v-card-title>
        <v-card-title v-else>
          {{ $t("quota_storage.headerset") }}
          <v-spacer></v-spacer>
          <v-icon :color="color.theme" dense dark size="35">mdi-server</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text v-if="mutiprogress === false">
          <!-- จอคอม -->
          <div v-if="resolutionScreen >= 500">
            <v-card class=" mt-3 ml-10 mr-10 elevation-0">
              <v-card-title class="pb-0"
                ><v-icon class="pr-2">mdi-city</v-icon
                >{{ $t("quota_storage.quota_business") }}</v-card-title
              >
              <v-card-text class="pb-0 pt-2">
                <v-layout class="pl-16 pr-16 ml-16 mr-16 pt-0 pb-0 mt-0">
                  <div>
                    <v-progress-circular
                      v-if="percent_remain > 40"
                      :rotate="90"
                      :width="20"
                      :size="100"
                      :value="percent_remain"
                      color="light-green"
                      ><strong class="caption font-weight-light"
                        >{{ percent_remain }}%</strong
                      ></v-progress-circular
                    >
                    <v-progress-circular
                      v-else-if="percent_remain <= 40 && percent_remain > 10"
                      :rotate="90"
                      :width="20"
                      :size="100"
                      :value="percent_remain"
                      color="amber"
                      ><strong class="caption font-weight-light"
                        >{{ percent_remain }}%</strong
                      ></v-progress-circular
                    >
                    <v-progress-circular
                      v-else-if="percent_remain <= 10"
                      :rotate="90"
                      :width="20"
                      :size="100"
                      :value="percent_remain"
                      color="red"
                      ><strong class="caption font-weight-light"
                        >{{ percent_remain }}%</strong
                      ></v-progress-circular
                    >
                  </div>
                  <v-list two-line subheader>
                    <v-list-item class="pt-0">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="remain_business"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-text="$t('quota_storage.text_remain_bus')"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-layout>
              </v-card-text>
            </v-card>
            <v-card class=" mt-0 ml-10 mr-10  elevation-0" v-if="statusmulti === 'ONE'">
              <v-card-title class="pb-0"
                ><v-icon class="pr-2">mdi-account</v-icon
                >{{ $t("quota_storage.text_set_quota_head") }}</v-card-title
              >
              <v-card-text v-if="resolutionScreen >= 500">
                <!-- <br/> -->
                <v-layout class="pl-16 pr-16 ml-16 mr-16 ">
                  <v-list two-line subheader>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="quota_storage"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-text="$t('quota_storage.text_set_quota')"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-layout>
                <!-- <br /> -->
                <v-layout class="pl-16 pr-16 ml-16 mr-16 ">
                  <v-list two-line subheader>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="remain_storage"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-text="$t('quota_storage.text_remain')"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <v-list two-line subheader>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="used_storage"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-text="$t('quota_storage.text_used')"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-layout>
                <!-- <br /> -->

                <!-- <v-layout class="pl-16 pr-16 ml-16 mr-16 "><v-text-field v-model="storan" label="ใส่ความจุที่ต้องการแก้ไข" dense outlined hide-details  type="number"></v-text-field>
              <v-select
              v-model="selectsize"
            :items="itemsize"       
            outlined
            dense
            ></v-select>
            </v-layout> -->
              </v-card-text>
            </v-card>
            <v-card class=" mt-3 ml-10 mr-10 mb-2 elevation-2">
              <v-card-title
                ><v-icon class="pr-2">settings</v-icon
                >{{ $t("quota_storage.setstorage") }}</v-card-title
              >
              <v-card-text>
                <!-- <br/> -->
                <v-layout class="pl-16 pr-16 ml-16 mr-16 "
                  ><v-text-field
                    v-model="edit_storage"
                    :label="$t('quota_storage.text_edit')"
                    dense
                    outlined
                    hide-details
                    type="number"
                    min="0"
                  ></v-text-field>
                  <v-select
                    v-model="selectsize"
                    :items="itemsize"
                    outlined
                    dense
                  ></v-select
                ></v-layout>
              </v-card-text>
            </v-card>
          </div>
          <!-- จอโทรศัพท์ -->
          <div v-else>
            <v-card class="elevation-0 ma-1">
              <v-card-title v-if="resolutionScreen < 400" class="pa-2 pt-4">
                <v-icon class="pr-2">mdi-city</v-icon>{{$t("quota_storage.quota_business")}}
              </v-card-title>
              <v-card-title v-else class="pb-0">
                <v-icon class="pr-2">mdi-city</v-icon>
                <span>{{$t("quota_storage.quota_business")}}</span>
              </v-card-title>
              <v-card-text class="mt-2 pa-0">
                <v-layout row wrap justify-center>
                  <v-flex sm6 xs5 class="text-right">
                    <v-progress-circular
                      v-if="percent_remain > 40"
                      :rotate="90"
                      :width="20"
                      :size="100"
                      :value="percent_remain"
                      color="light-green"
                    >
                      <strong class="caption font-weight-light">
                        {{ percent_remain }}%
                      </strong>
                    </v-progress-circular>
                    <v-progress-circular
                      v-else-if="percent_remain <= 40 && percent_remain > 10"
                      :rotate="90"
                      :width="20"
                      :size="100"
                      :value="percent_remain"
                      color="amber"
                      ><strong class="caption font-weight-light"
                        >{{ percent_remain }}%</strong
                      ></v-progress-circular
                    >
                    <v-progress-circular
                      v-else-if="percent_remain <= 10"
                      :rotate="90"
                      :width="20"
                      :size="100"
                      :value="percent_remain"
                      color="red"
                      ><strong class="caption font-weight-light"
                        >{{ percent_remain }}%</strong
                      ></v-progress-circular
                    >
                  </v-flex>
                  <v-flex sm6 xs7 class="text-left pt-4 pl-2">
                    <v-list-item-title
                      v-text="remain_business"
                    ></v-list-item-title>
                    <v-list-item-subtitle
                      v-text="$t('quota_storage.text_remain_bus')"
                    ></v-list-item-subtitle>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
            <v-card class="elevation-0 ma-1" v-if="statusmulti === 'ONE'">
              <v-card-title class="pa-2 pt-4"
                ><v-icon class="pr-2">mdi-account</v-icon>&nbsp;{{
                  $t("quota_storage.text_set_quota_head")
                }}</v-card-title
              >
              <v-card-text>
                <!-- <v-list two-line subheader>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="quota_storage"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-text="$t('quota_storage.text_set_quota')"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-list two-line subheader>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="remain_storage"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-text="$t('quota_storage.text_remain')"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-list two-line subheader>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="used_storage"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-text="$t('quota_storage.text_used')"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list> -->
                <v-layout row wrap justify-center>
                    <v-list two-line subheader>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="quota_storage"
                          ></v-list-item-title>
                          <v-list-item-subtitle
                            v-text="$t('quota_storage.text_set_quota')"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                    <v-list two-line subheader>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="remain_storage"
                          ></v-list-item-title>
                          <v-list-item-subtitle
                            v-text="$t('quota_storage.text_remain')"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                    <v-list two-line subheader>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="used_storage"
                          ></v-list-item-title>
                          <v-list-item-subtitle
                            v-text="$t('quota_storage.text_used')"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                </v-layout>
              </v-card-text>
            </v-card>
            <v-card class="mt-0 ml-4 mr-4">
              <v-card-title
                ><v-icon class="pr-2">settings</v-icon>&nbsp;{{
                  $t("quota_storage.setstorage")
                }}</v-card-title
              >
              <v-card-text>
                <v-text-field
                  v-model="edit_storage"
                  :label="$t('quota_storage.text_edit')"
                  dense
                  outlined
                  hide-details
                  type="number"
                  min="0"
                ></v-text-field>
                <v-select
                  class="pt-1"
                  v-model="selectsize"
                  :items="itemsize"
                  outlined
                  dense
                ></v-select>
              </v-card-text>
              <br />
            </v-card>
          </div>
        </v-card-text>
        <v-card-text v-else>
          <v-list dense tile>
            <v-list-item
              two-line
              v-for="item in progressdatasettingstorage"
              :key="type === 'de' ? item.department_id : item.account_id"
            >
              <v-list-item-avatar>
                <v-icon :color="item.color" large>
                  {{item.icon}}
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-if="$t('default') === 'en'">{{ item.user_name_en }}</v-list-item-title>
                <v-list-item-title v-else> {{ item.user_name_th }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-chip class="ma-2 font-weight-bold" :color="item.color" label outlined>
                  {{ $t(item.message) }}
                </v-chip>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" outlined @click="close()">{{
            $t("admin.close")
          }}</v-btn>
          <v-btn
            :color="color.theme"
            @click="check_statusmuti()"
            :loading="loadprogress"
            :disabled="mutiprogress"
            class="white--text"
            >{{ $t("admin.submit") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  mixins: [validationMixin],
  validations: {
    filename: { required },
  },
  props: ["show", "datastorage", "remain_business", "type", "percent_remain", "datamultiplesetting", "statusmulti"],
  data: function() {
    return {
      loadprogress: false,
      selectsize: "GB",
      itemsize: ["KB", "MB", "GB", "TB"],
      quota_storage: "",
      remain_storage: "",
      used_storage: "",
      edit_storage: 0,
      storau: "1 GB",
      storan: 50,
      selectSize: "",
      filename: "",
      createprogress: false,
      zoom: 0,
      test: 1,
      unitremain: "",
      updatestorage: 0,
      maxstorage: 0,
      remain_storage: 0,
      sizeStorage: ["MB", "GB"],
      totalstorage: 0,
      unit_storage_account: "",
      plusnum: 1,
      maxstorageupdate: "",
      mutiprogress: false,
      progressdatasettingstorage: [],
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    shower: {
      get() {
        if (this.show === true) {
        }
        return this.show;
      },
      set(value) {
        if (!value) {
        }
      },
    },
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    // sizeallcomplete() {
    //   if (this.progressdatasettingstorage.length === 0 && this.mutiprogress === true && this.statusmulti === 'ALL') {
    //     return "700px"
    //   } else {
    //     return "700px"
    //   }
    // }
  },
  watch: {
    show: function(val) {
      if (val === true) {
        this.mutiprogress = false;
        if (this.statusmulti === "ONE") {
          console.log(this.datastorage, this.remain_business);
          this.remain_storage = this.datastorage["remain_storage"];
          this.used_storage = this.datastorage["used_storage"];
          this.quota_storage = this.datastorage["quota_storage"];
          this.convertstorage(this.datastorage.quota_storage);
        } else {
          this.remain_storage = "-";
          this.used_storage = "-";
          this.quota_storage = "-";
        }
      } else {
        //this.$emit("closemanagestorage");
      }
    },
  },
  methods: {
    close() {
      if (this.mutiprogress === true) {
        this.test = 1;
        this.loadprogress = false;
        this.mutiprogress = false;
        this.progressdatasettingstorage = [];
        this.$emit("closemanagestorage");
      } else {
        this.$emit("close");
        this.test = 1;
        this.loadprogress = false;
        this.mutiprogress = false;
      }
    },
    convertstorage(parameter) {
      let res_ = parameter.split(" ");
      this.edit_storage = parseFloat(res_[0]);
      this.selectsize = res_[1] === "set" ? "KB" : res_[1];
      console.log(this.edit_storage);
    },
    check_statusmuti() {
      if (this.statusmulti === "ONE") {
        this.fn_update_storge();
      } else {
        this.fn_muti_update_storge();
      }
    },
    async fn_update_storge() {
      this.loadprogress = true;
      let id = [];
      let type = "";
      if (this.type === "de") {
        id.push(this.datastorage["department_id"]);
        type = "department";
      } else {
        id.push(this.datastorage["account_user"]);
        type = "employee";
      }
      console.log("id", id, this.edit_storage);
      var payload = {
        account_admin: this.dataAccountId,
        id: id,
        business_id: this.dataAccountActive.business_info.business_id,
        unit: this.selectsize,
        storage: this.edit_storage.toString() === 'NaN' || NaN ? "0" : this.edit_storage.toString(),
        type: type,
      };
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_ADMIN_BOX +
            "/api/admin/update_storage_emlpoyee_or_department",
          payload,
          { headers: { Authorization: auth.code } }
        )
        .then((response) => {
          console.log("file", response);

          if (response.data.status === "OK") {
            this.loadprogress = false;
            if (response.data.update_error.length > 0) {
              if (response.data.update_error[0]["errorMessage"] === "Can't find in data") {
                Toast.fire({
                  icon: "error",
                  title: this.$t("quota_storage.cannotfinddata")
                });
              } else if (response.data.update_error[0]["errorMessage"] === "Can't find employee in business") {
                Toast.fire({
                  icon: "error",
                  title: this.$t("quota_storage.cannotfindemployeeinbiz")
                });
              } else if (response.data.update_error[0]["errorMessage"] === "Cannot update because the storage is less than the used space") {
                Toast.fire({
                  icon: "error",
                  title: this.$t("quota_storage.cannotupdatemorespace")
                });
              } else {
                Toast.fire({
                  icon: "error",
                  title: this.$t("quota_storage.cannotupdate")
                });
              }
            } else {
              this.$emit("closemanagestorage");
              Toast.fire({
                icon: "success",
                // title: "ตั้งค่าสำเร็จ",
                title: this.$t("admintoast.settingsuccess")
              });
            }
          } else if (response.data.errorMessage === "Not enough storage.") {
            this.loadprogress = false;
            Toast.fire({
              icon: "error",
              title: this.$t("admintoast.notenoughstorage")
            });
          } else {
            this.loadprogress = false;
            Toast.fire({
              icon: "error",
              // title:
              //   response.data.errorCode + ": " + response.data.errorMessage,
              title: this.$t("admintoast.errormanagequotastorage"),
            });
          }
        })
        .catch((error) => {
          this.loadprogress = false;
          Toast.fire({
            icon: "error",
            // title: error,
            title: this.$t("admintoast.errormanagequotastorage"),
          });
        });
    },
    async fn_muti_update_storge() {
      this.loadprogress = true;
      this.mutiprogress = true;
      let id = [];
      let type = this.type === "de" ? "department" : "employee";
      this.progressdatasettingstorage = [...this.datamultiplesetting];
      if (this.statusmulti === "SELECT") {
        for (let i = 0; i < this.datamultiplesetting.length; i++) {
          this.type === "de" ? id.push(this.datamultiplesetting[i]["department_id"]) : id.push(this.datamultiplesetting[i]["account_user"]);
          this.progressdatasettingstorage[i]["icon"] = "mdi-account";
          this.progressdatasettingstorage[i]["message"] = "quota_storage.updatingsetting";
          this.progressdatasettingstorage[i]["color"] = this.color.theme;
          if (this.datamultiplesetting.length === i + 1) {
            var payload = {
              account_admin: this.dataAccountId,
              id: id,
              business_id: this.dataAccountActive.business_info.business_id,
              unit: this.selectsize,
              storage: this.edit_storage.toString() === 'NaN' || NaN ? "0" : this.edit_storage.toString(),
              type: type,
            };
          }
        }
      } else {
        var payload = {
          account_admin: this.dataAccountId,
          id: id,
          business_id: this.dataAccountActive.business_info.business_id,
          unit: this.selectsize,
          storage: this.edit_storage.toString() === 'NaN' || NaN ? "0" : this.edit_storage.toString(),
          type: type,
        };
      }
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_ADMIN_BOX +
            "/api/admin/update_storage_emlpoyee_or_department",
          payload,
          { headers: { Authorization: auth.code } }
        )
        .then((response) => {
          console.log("file", response);
          if (response.data.status === "OK") {
            if (response.data.update_error.length > 0) {
              if (this.statusmulti === "SELECT") {
                for (let i = 0; i < response.data.update_error.length; i++) {
                  let index = this.progressdatasettingstorage.findIndex((element) => element.account_user === response.data.update_error[i].account_id);
                  console.log("index", index);
                  console.log("this.progressdatasettingstorage", this.progressdatasettingstorage);
                  console.log("response.data.update_error[i].account_id", response.data.update_error[i].account_id);
                  // console.log("index", index);
                  this.progressdatasettingstorage[index]["icon"] = "mdi-account-alert";
                  if (response.data.update_error[i]["errorMessage"] === "Can't find in data") {
                    this.progressdatasettingstorage[index]["message"] = "quota_storage.cannotfinddata";
                  } else if (response.data.update_error[i]["errorMessage"] === "Can't find employee in business") {
                    this.progressdatasettingstorage[index]["message"] = "quota_storage.cannotfindemployeeinbiz"
                  } else if (response.data.update_error[i]["errorMessage"] === "Cannot update because the storage is less than the used space") {
                    this.progressdatasettingstorage[index]["message"] = "quota_storage.cannotupdatemorespace"
                  } else {
                    this.progressdatasettingstorage[index]["message"] = "quota_storage.cannotupdate";
                  }
                  this.progressdatasettingstorage[index]["color"] = "error";
                  if (response.data.update_error.length === i + 1) {
                    for (let j = 0; j < this.progressdatasettingstorage.length; j++) {
                      if (this.progressdatasettingstorage[j]["icon"] !== "mdi-account-alert") {
                        this.progressdatasettingstorage[j]["icon"] = "mdi-account-check";
                        this.progressdatasettingstorage[j]["message"] = "quota_storage.updatesettingsuccess";
                        this.progressdatasettingstorage[j]["color"] = "success";
                      }
                    }
                  }
                }
              } else {
                this.progressdatasettingstorage = [...response.data.update_error]
                console.log("f", this.progressdatasettingstorage);
                for (let i = 0; i < this.progressdatasettingstorage.length; i++) {
                  this.progressdatasettingstorage[i]["icon"] = "mdi-account-alert";
                  if (this.progressdatasettingstorage[i]["errorMessage"] === "Can't find in data") {
                    this.progressdatasettingstorage[i]["message"] = "quota_storage.cannotfinddata";
                  } else if (this.progressdatasettingstorage[i]["errorMessage"] === "Can't find employee in business") {
                    this.progressdatasettingstorage[i]["message"] = "quota_storage.cannotfindemployeeinbiz"
                  } else if (this.progressdatasettingstorage[i]["errorMessage"] === "Cannot update because the storage is less than the used space") {
                    this.progressdatasettingstorage[i]["message"] = "quota_storage.cannotupdatemorespace"
                  } else {
                    this.progressdatasettingstorage[i]["message"] = "quota_storage.cannotupdate";
                  }
                  this.progressdatasettingstorage[i]["color"] = "error";
                  console.log("1212", this.progressdatasettingstorage[i]);
                }
                console.log("f", this.progressdatasettingstorage);
              }
            } else {
              if (this.statusmulti === "SELECT") {
                for (let j = 0; j < this.progressdatasettingstorage.length; j++) {
                  this.progressdatasettingstorage[j]["icon"] = "mdi-account-check";
                  this.progressdatasettingstorage[j]["message"] = "quota_storage.updatesettingsuccess";
                  this.progressdatasettingstorage[j]["color"] = "success";
                }
              }
            }
            this.loadprogress = false;
          } else if (response.data.errorMessage === "Not enough storage.") {
            this.mutiprogress = false;
            this.loadprogress = false;
            Toast.fire({
              icon: "error",
              title: this.$t("admintoast.notenoughstorage")
            });
          } else {
            this.mutiprogress = false;
            this.loadprogress = false;
            Toast.fire({
              icon: "error",
              // title:
              //   response.data.errorCode + ": " + response.data.errorMessage,
              title: this.$t("admintoast.errormanagequotastorage"),
            });
          }
        })
        .catch((error) => {
          console.log("error", error);
          this.mutiprogress = false;
          this.loadprogress = false;
          Toast.fire({
            icon: "error",
            // title: error,
            title: this.$t("admintoast.errormanagequotastorage"),
          });
        });
    },
  },
};
</script>
<style></style>
